/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import "./layout.scss"

const Layout = ({ children }) => {

  return (
    <>
      <div      >
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, 
          {` `}
          <a href="https://yucolab.com">yucolab</a>
        </footer>
      </div>
    </>
  )//return
}//const Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
